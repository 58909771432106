@import '../../../../assets/styles/mixins.scss';

.footer {
  max-width: rem(1560);
  margin: 0 auto;
}

.inner {
  padding: rem(15) rem(30) rem(10);
  margin: rem(0) rem(30) 0;
  border-radius: 5px 5px 0 0;
  color: $text-darken-more;
  background: $gray-border;
  @media (max-width: $sm-max-width) {
    margin: 0 rem(10) !important;
  }
}

.copyright {
  display: flex;
  justify-content: center;
}
