@import '../../../../assets/styles/mixins.scss';

.menu {
  box-sizing: content-box;
  position: relative;

  :global(.ant-menu-light) {
    &:before,
    &:after {
      content: '';
      display: block;
      width: 1px;
      height: 100%;
      background: $gray-darken;
      opacity: 0.4;
      position: absolute;
      top: 0;
      left: 0;
    }

    &:after {
      left: auto;
      right: 0;
    }
  }

  :global(.ant-layout-sider-children) {
    height: 100%;
    width: 256px;
    transition: width 0.2s;

    @media (min-width: 1000px) {
      position: fixed;
    }
  }

  &:global(.ant-layout-sider-collapsed) {
    :global(.ant-layout-sider-children) {
      width: 80px;
    }
  }

  :global(.ant-menu-inline-collapsed-tooltip) .icon {
    display: none;
  }

  :global(.ant-layout-sider-zero-width-trigger) {
    top: 10px !important;
  }

  :global(.ant-menu-item),
  :global(.ant-menu-submenu-title) {
    transition: padding 0s !important;
  }

  :global(.ant-layout-sider-trigger) {
    max-width: 255px;
  }

  // avoid splitting text into several lines when menu is expanding
  :global(.ant-menu-item-group-title) {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.logo {
  height: 64px;
  background: $white;
  overflow: hidden;
}

.logoContainer {
  height: 64px;
  text-align: left;
  padding-left: rem(16);

  img {
    width: 158px;
    height: 58px;
  }
}
:global(.ant-layout-sider-collapsed).menu {
  .logoContainer {
    padding-top: rem(8);

    img {
      width: 50px;
      height: 50px;
    }
  }

  :global(.ant-layout-sider-trigger) {
    max-width: 79px;
  }
}

.navigation {
  padding: rem(15) 0 rem(30) !important;
  border: none;

  > :global(.ant-menu-item-divider) {
    margin: 0 23px !important;
  }

  :global(.ant-menu-submenu-inline) > :global(.ant-menu-submenu-title .ant-menu-submenu-arrow) {
    right: 50px;
  }

  &:global(.ant-menu-inline),
  &:global(.ant-menu-vertical),
  &:global(.ant-menu-vertical-left) {
    border-right: none;
  }

  &:global(.ant-menu-inline-collapsed) {
    .title {
      display: none;
    }

    :global(.ant-menu-submenu) > :global(.ant-menu-submenu-title .ant-menu-submenu-arrow) {
      display: block;
      opacity: 0.2;
      top: 48%;
      right: 14px;
    }
  }

  &:global(.ant-menu-light) {
    :global(.ant-menu-submenu-arrow) {
      opacity: 0.4;
    }

    :global(.ant-menu-item:after) {
      right: auto;
      left: 0;
    }

    :global(.ant-menu-item),
    :global(.ant-menu-submenu) {
      &:hover {
        .icon {
          color: $text;
        }
      }
    }

    :global(.ant-menu-item),
    :global(.ant-menu-submenu-title) {
      width: 100%;
    }
  }
}

.light {
  background: $white;

  :global(.ant-layout-sider-trigger) {
    color: inherit;
    background: $gray-border;
  }

  :global(.ant-menu-item) > :global(a) {
    color: $text;
    &:hover {
      color: $blue;
    }
  }

  :global(.drawer-content) {
    background: $white !important;
  }
}

:global(.ant-menu-inline-collapsed-tooltip) {
  :global(.icon-collapsed-hidden) {
    display: none;
  }
}

:global(.ant-menu-inline-collapsed) {
  :global(.badge-collapsed-hidden) {
    display: none;
  }
}
