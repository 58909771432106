@import "mixins";

.clickable {
  cursor: pointer;
  color: $text;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: #08f;
  }
}
